import React, { Component } from "react"
import "./hero.scss"
/* eslint-disable */

export default class Jobs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      data: [],
    }
    // This binding is necessary to make `this` work in the callback
    this.popupWindow = this.popupWindow.bind(this)
  }
  componentDidMount() {
    fetch("https://celer-network-5d8f644bd292.breezy.hr/json")
      .then(res => res.json())
      .then(res => {
        this.setState({
          isLoaded: true,
          data: res,
        })
      })
  }

  popupWindow = (URL, windowName, windowWidth, windowHeight) => {
    var centerLeft = window.screen.width / 2 - windowWidth / 2
    var centerTop = window.screen.height / 2 - windowHeight / 2
    var windowFeatures =
      "toolbar=no, location=no, directories=no, status=no, menubar=no, titlebar=no, scrollbars=no, resizable=no, "
    return window.open(
      URL,
      windowName,
      windowFeatures +
        " width=" +
        windowWidth +
        ", height=" +
        windowHeight +
        ", top=" +
        centerTop +
        ", left=" +
        centerLeft
    )
  }

  render() {
    return (
      <div className="jobs">
        <div className="jobs-body">
          {this.state.data.map(data => (
            <div className="job-item" key={data.id}>
              <div className="item-left">
                <div className="item-left-text">{data.name}</div>
                <div className="item-left-text2">
                  {data.type.name}, {data.location.name}
                </div>
              </div>
              <div className="item-right">
                <div
                  className="apply-btn"
                  onClick={() => window.open(data.url, "_blank")}
                >
                  Apply Now
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
