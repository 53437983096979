import React from "react"
import QueueAnim from "rc-queue-anim"

import "./hero.scss"
import joinUs from "../../images/career/joinUs.svg"
import UseIsMobile from "../../hooks/UseIsMobile"

export default function Hero() {
  const [isMobile] = UseIsMobile()
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="career-hero">
      <div className="career-hero-body">
        <MyQueueAnim duration={2000} type="bottom" key="1">
          <div className="career-hero-title" key="join">
            Join us
          </div>
        </MyQueueAnim>
        <MyQueueAnim duration={3000} type="bottom" delay={300} key="2">
          <div className="career-hero-content" key="title">
            Our team is composed of top-tier researchers with PhDs from leading
            universities such as MIT,
            <div></div>
            UC Berkeley, Princeton, and UIUC, as well as seasoned industry
            veterans from companies such as Google, VMWare, Intel, Amazon,
            Citadel, Baidu and Alibaba. Together, we foster a culture of
            innovation that values broad impact, proactive ownership,
            transparent and constructive communication, and no-regret execution.
            We’re a close-knit team that thrives on collaboration and welcomes
            diverse perspectives. If these values resonate with you and you’re
            excited about bringing cutting-edge technology to the masses, we
            invite you to apply for our open positions.
          </div>
        </MyQueueAnim>
        <MyQueueAnim duration={3000} delay={500} type="bottom" key="1">
          <div className="career-hero-img" key="bottomImg">
            <img src={joinUs} alt="" />
          </div>
        </MyQueueAnim>
      </div>
    </div>
  )
}
